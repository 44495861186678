import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Hero } from '../components/InnerPages/Hero';
import SaveMoneyIcon from '../images/SaveMoney';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface ISaveMoneyProps {
  location: any;
}

const SaveMoney = ({ location }: ISaveMoneyProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "50727";
        _clcktt['cIdMobile'] = "124495";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Start Saving Today" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<SaveMoneyIcon />}
          title="Start Saving Today"
          description="You can save an extra $500 by using these apps"
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SaveMoney;
